/*
 * Copyright LaserFlare Studio
 * https://laserflare.net
 * You may not copy or use this code for your own
 * Developed and maintained by MadFox
 */

import React from "react";
import {Route, Switch} from "react-router-dom";

const VotePage = React.lazy(() => import("./pages/VotePage"));
const DiscordPage = React.lazy(() => import("./pages/DiscordPage"));
const FeedbackPage = React.lazy(() => import("./pages/FeedbackPage"));
const StorePage = React.lazy(() => import('./pages/StorePage'));
const SocialMedia = React.lazy(() => import("./pages/SocialMedia"));
const Rules = React.lazy(() => import("./pages/Rules"));

const Team = React.lazy(() => import("./pages/Team"));
const HomePage = React.lazy(() => import("./pages/HomePage"));

const Error404 = React.lazy(() => import("./pages/Error404"));

class Routes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/vote" component={VotePage}/>
                <Route exact path="/social" component={SocialMedia}/>
                <Route exact path="/discord" component={DiscordPage}/>
                <Route exact path="/feedback" component={FeedbackPage}/>
                <Route exact path="/rules" component={Rules}/>
                <Route exact path="/store" component={StorePage}/>
                <Route exact path="/team" component={Team}/>
                <Route
                    render={function () {
                        return <Error404/>;
                    }}
                />
            </Switch>
        );
    }
}

export default Routes;
