import {createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  
  .dbg {
      background: ${({theme}) => theme.body};
      transition: all 0.25s linear;
  }
  
  .dtext {
      color: ${({theme}) => theme.text};
      transition: all 0.25s linear;
  }
  
  .dbgBS {
      background: ${({theme}) => theme.body};
      box-shadow: 0 2px 5px 0 ${({theme}) => theme.shadow};
      transition: all 0.25s linear;
  }
  
  .dnav {
      background: ${({theme}) => theme.nav};
  }

  body {
    align-items: center;
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
    height: 100vh;
    margin: 0;
    padding: 0;
    font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    transition: all 0.25s linear;
  };
    `;