// import {endpoint} from '../config';
import enLocale from "./assets/locales/en";
import ruLocale from "./assets/locales/ru";
// import axios from "axios";

export default class Locale {
    static getLocale = (language) => {
        // return await axios.get(`${endpoint}/locales/${language}`, {timeout: 1000, responseType: "json"})
        //     .then()
        //     .catch(e => {
        console.log(`Getting locale for ${language}...`);
        switch (language.toLowerCase()) {
            case "ru":
            case "ru-ru":
            case "ru_ru":
            case "be":
            case "be-by":
            case "be_by":
            case "uk":
            case "uk-uk":
            case "uk_uk":
                console.log("Setting ru locale");
                return ruLocale;
            case "en":
            case "en-us":
            case "en_us":
            case "en-uk":
            case "en_uk":
                console.log("Setting en locale");
                return enLocale;
            default:
                console.log("Setting default(en) locale");
                return enLocale;
        }
    };
}