export const lightTheme = {
    nav: '#0099ff',
    navTxt: '',
    body: '#fbfbfb',
    text: '#363537',
    shadow: 'rgba(0,0,0,0.16)',
    toggleBorder: '#FFF',
    gradient: 'linear-gradient(#39598A, #79D7ED)',
};

export const darkTheme = {
    nav: '#161939',
    body: '#363537',
    text: '#FAFAFA',
    shadow: 'rgba(0,0,0,0.16)',
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#091236, #1E215D)',
};