/*
 * Copyright LaserFlare Studio
 * https://laserflare.net
 * You may not copy or use this code for your own
 * Developed and maintained by MadFox
 */

import React from "react";
import ReactDOM from "react-dom";
// import "@fortawesome/fontawesome-free/css/all.min.css";
// import "bootstrap-css-only/css/bootstrap.min.css";
// import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./App";

import registerServiceWorker from './registerServiceWorker';

import ruLocale from './assets/locales/ru.json';
import enLocale from './assets/locales/en.json'

import cookies from "js-cookie";

export function setLocale(lang) {
    switch (lang.toLowerCase()) {
        case "ru":
        case "ru-ru":
        case "ru_ru":
        case "be":
        case "be-by":
        case "be_by":
        case "uk":
        case "uk-uk":
        case "uk_uk":
            cookies.set("language", "ru", {expires: 365 * 3});
            console.log("Мы установили Ваш язык на Русский");
            return ruLocale;
        case "en":
        case "en-us":
        case "en_us":
        case "en-uk":
        case "en_uk":
            cookies.set("language", "en", {expires: 365 * 3});
            console.log("Your language is English");
            return enLocale;
        default:
            cookies.set("language", "default", {expires: 365 * 3});
            console.log("We haven't translation for your language, will be used English");
            return enLocale;
    }
}

ReactDOM.render(
    <div>
        <App setLang={(l) => setLocale(l)}/>
    </div>,
    document.getElementById('root'));

registerServiceWorker();