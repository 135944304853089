import React, {Component} from 'react';
import {MDBCollapse, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBNavItem, MDBNavLink} from "mdbreact";
import logo from "../assets/logo.png";
import {FormattedMessage} from "react-intl";
import {ReactComponent as RuFlag} from "../assets/media/images/language/flags/248-russia.svg"
import {ReactComponent as EnFlag} from "../assets/media/images/language/flags/226-united-states.svg";
import {ReactComponent as TranslateImg} from "../assets/media/images/language/translate.svg";
import Toggle from "./Toggle";

class Header extends Component {
    state = {
        collapseID: ""
    };

    toggleCollapse = collapseID => () =>
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));

    closeCollapse = collapseID => () => {
        window.scrollTo(0, 0);
        this.state.collapseID === collapseID && this.setState({collapseID: ""});
    };

    render() {
        const overlay = (
            <div
                id="sidenav-overlay"
                style={{backgroundColor: "transparent"}}
                onClick={this.toggleCollapse("mainNavbarCollapse")}
            />
        );

        const {collapseID} = this.state;

        return (
            <MDBNavbar className='dnav' dark expand="md" fixed="top" scrolling>
                {collapseID && overlay}
                <MDBNavbarBrand href="/" className="py-0 font-weight-bold">
                    <img alt="logo" title='logo' loading='lazy' src={logo} className="mr-2"
                         style={{height: "2.5rem", width: "2.5rem"}}/>
                    <strong className="align-middle" style={{fontFamily: "Piglet Ghost", letterSpacing: "0.1em", fontSize: "1.5rem"}} translate="no">LaserFlare Network</strong>
                </MDBNavbarBrand>
                <MDBNavbarToggler
                    onClick={this.toggleCollapse("mainNavbarCollapse")}
                />
                <MDBCollapse
                    id="mainNavbarCollapse"
                    isOpen={this.state.collapseID}
                    navbar
                >
                    <MDBNavbarNav right>
                        {/*<MDBNavItem>
                            <Toggle theme={this.props.theme} toggleTheme={(theme) => this.props.setTheme(theme)}/>
                        </MDBNavItem>*/}
                        <MDBNavItem>
                            <MDBNavLink
                                exact
                                to="/"
                                onClick={this.closeCollapse("mainNavbarCollapse")}
                            >
                                <strong>
                                    <FormattedMessage
                                        id="HomePage"
                                        defaultMessage={`Home page`}
                                    />
                                </strong>
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                onClick={this.closeCollapse("mainNavbarCollapse")}
                                to="/vote"
                            >
                                <strong>
                                    <FormattedMessage
                                        id="Vote"
                                        defaultMessage={`Vote`}
                                    />
                                </strong>
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                onClick={this.closeCollapse("mainNavbarCollapse")}
                                to="/social"
                            >
                                <strong><FormattedMessage id="Social"
                                                          defaultMessage={`Social`}/></strong>
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                onClick={this.closeCollapse("mainNavbarCollapse")}
                                to="/discord"
                            >
                                <strong>Discord</strong>
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                onClick={this.closeCollapse("mainNavbarCollapse")}
                                to="/feedback"
                            >
                                <strong><FormattedMessage id="Feedback"
                                                          defaultMessage={`Feedback`}/></strong>
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                onClick={this.closeCollapse("mainNavbarCollapse")}
                                to="/rules"
                            >
                                <strong><FormattedMessage id="Rules" defaultMessage={`Rules`}/></strong>
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                onClick={this.closeCollapse("mainNavbarCollapse")}
                                to="/store"
                            >
                                <strong><FormattedMessage id="Store" defaultMessage={`Store`}/></strong>
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownToggle nav caret>
                                    <TranslateImg style={{height: '1.3rem'}}/>
                                    <span style={{fontSize: "0"}}>Language</span>
                                </MDBDropdownToggle>
                                <MDBDropdownMenu className='dnav'>
                                    <MDBDropdownItem className='dtext' onClick={() => this.props.setLang("en")}><EnFlag style={{height: "1.75rem", marginRight: '0.25rem'}}/>English</MDBDropdownItem>
                                    <MDBDropdownItem className='dtext' onClick={() => this.props.setLang("ru")}><RuFlag style={{height: "1.75rem", marginRight: '0.25rem'}}/>Русский</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        );
    }
}

export default Header;