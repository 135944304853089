/*
 * Copyright LaserFlare Studio
 * https://laserflare.net
 * You may not copy or use this code for your own
 * Developed and maintained by MadFox
 */

import React, {Component, Suspense} from "react";
import {MDBFooter, MDBIcon} from "mdbreact";
import {BrowserRouter as Router, Link} from "react-router-dom";
import Routes from "./Routes";
import Loader from "./utils/Loader/Loader";

import GAListener from "./utils/Analytics";
import {IntlProvider} from "react-intl";
import loc from "./locale"
import {setLocale} from "./index"
import cookies from "js-cookie";
import Header from "./components/header";
import {ThemeProvider} from 'styled-components';
import {darkTheme, lightTheme} from './theme';
import {GlobalStyles} from './global';

class App extends Component {
    state = {
        locale: {},
        theme: {}
    };

    componentWillMount() {
        this.initLocale();
        this.initTheme();
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                this.setState({theme: darkTheme});
                window.localStorage.setItem("theme", 'dark');
            } else {
                this.setState({theme: lightTheme});
                window.localStorage.setItem("theme", 'light');
            }
        })
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({})
        }, 1000)
    }

    initLocale = () => {
        if (!cookies.get("language"))
            setLocale(navigator.language);
        else {
            this.setState({locale: loc.getLocale(cookies.get("language"))});
        }
    };

    initTheme = () => {
        if (!window.localStorage.getItem("theme")) {
            if (window.matchMedia('(prefers-color-scheme: dark)').matches)
                window.localStorage.setItem("theme", 'dark');
            else window.localStorage.setItem("theme", 'light');
            this.setState({theme: this.setTheme(window.localStorage.getItem("theme"))});
        } else {
            let isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
            let localTheme = window.localStorage.getItem("theme");
            let localIsDark = localTheme === 'dark';
            if (isDark === localIsDark)
                this.setState({theme: this.setTheme(localTheme)});
            else
                if (isDark) this.setState({theme: this.setTheme('dark')});
                else this.setState({theme: this.setTheme('light')});
        }
    };

    setTheme = (theme) => {
        console.log("Cool")
        if (theme === 'light') {
            this.setState({theme: lightTheme});
            window.localStorage.setItem("theme", 'light');
            return lightTheme;
        }
        else {
            this.setState({theme: darkTheme});
            window.localStorage.setItem("theme", 'dark');
            return darkTheme;
        }
    };

    render() {
        return (
            <ThemeProvider theme={this.state.theme}>
                {document.getElementsByTagName("body")[0].setAttribute("class", "dbg")}
                <GlobalStyles/>
                <IntlProvider locale={navigator.language} messages={this.state.locale}>
                    <Router>
                        <Suspense fallback={<Loader/>}>
                            <GAListener>
                                <div className="flyout">
                                    <Header setLang={(l) => {
                                        this.props.setLang(l);
                                        this.initLocale()
                                    }} theme={this.state.theme} setTheme={(theme) => this.setTheme(theme)}/>
                                    <main style={{marginTop: "4rem"}}>
                                        <Suspense fallback={<Loader/>}>
                                            <Routes/>
                                        </Suspense>
                                    </main>
                                    <MDBFooter className="dnav" translate="no">
                                        <p className="footer-copyright mb-0 py-3 text-center">
                                            Powered with <MDBIcon className='red-text' icon="heart"/> and <MDBIcon fab className='blue-text' icon="react"/> by <a
                                            href='https://madfox.laserflare.net'>MadFox</a> | © 2017-{new Date().getFullYear()} <a
                                            href="https://laserflare.net">LaserFlare Studio</a> |
                                            <Link to="/team"> Our team</Link>
                                        </p>
                                    </MDBFooter>
                                </div>
                            </GAListener>
                        </Suspense>
                    </Router>
                </IntlProvider>
            </ThemeProvider>
        );
    }
}

export default App;
