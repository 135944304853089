/*
 * Copyright LaserFlare Studio
 * https://laserflare.net
 * You may not copy or use this code for your own
 * Developed and maintained by MadFox
 */

import React from 'react'
import '../Loader/Loading.css'

export default () => (
    <div className="lds-css ng-scope w-100 h-100">
        <div className="lds-double-ring">
            <div></div>
            <div></div>
            <div>
                <div></div>
            </div>
            <div>
                <div></div>
            </div>
        </div>
    </div>
)